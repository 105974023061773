import { Controller } from 'stimulus';
import Swiper, { A11y, Navigation, Pagination } from 'swiper';

// Register Swiper modules
Swiper.use([A11y, Navigation, Pagination]);

export default class extends Controller {
  static targets = ['slider', 'pagination', 'next', 'prev'];

  initialize() {
    this.applyFilter = this.applyFilter.bind(this);
    // Set a variable to store all slides, used to ensure filtered slider functions the same as unfiltered slider
    this.allItems = [];
  }

  connect() {
    // Short-circuit if the slider target does not exist
    if (!this.sliderTarget) return;

    // Determine whether Swiper is to be initialized or if we are using a grid layout
    this.usesSlider = this.sliderTarget?.dataset?.isSlider === 'true' || false;

    // Store all items as an array, for ease of filtering
    // Handled differently based on layout
    if (this.usesSlider) {
      // We are using Swiper, so store all items as an array keyed by the `.swiper-slide` selector
      this.allItems = Array.from(this.sliderTarget.querySelectorAll('.swiper-slide'));

      // Initialize the Swiper instance
      this.initSwiper();
    } else {

      // We are using a grid layout, so store all items as an array keyed by the `.grid__item` selector
      this.allItems = Array.from(this.sliderTarget.querySelectorAll('.grid__item'));
    }

    // Listen for filterChange events to apply the filter
    this.element.addEventListener('filterChange', this.applyFilter);
  }

  disconnect() {
    if (this.usesSlider) {
      this.destroySwiper();
    }

    this.element.removeEventListener('filterChange', this.applyFilter);
  }

  /**
   * initSwiper
   * - Initialize the Swiper instance
   */
  initSwiper() {
    // Ensure Swiper is initialized only once
    if (this.swiper) return;

    // Initialize the Swiper instance
    this.swiper = new Swiper(this.sliderTarget, {
      slidesPerView: 1,
      centeredSlides: true,
      centeredSlidesBounds: true,
      centerInsufficientSlides: true,
      autoHeight: true,
      a11y: {
        enabled: true,
      },
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
      speed: 450,
      on: {
        slideChange: (swiper) => {
          this.updateAriaNotification();
        },
      },
      breakpoints: {
        700: {
          slidesPerView: 2,
          spaceBetween: 64,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
      },
    });

    // Hack-fix for Swiper pagination not updating after a swipe gesture
    this.swiper.on('slideChangeTransitionEnd', () => {
      // Swiper pagination does not hold the correct index when swiping when there are multiple Swiper instances
      // Short-circuit if pagination does not exist
      if (!this.swiper.pagination) return;
      this.swiper.pagination.render();
      this.swiper.pagination.update();
    });

    // Update the aria-notification element with the initial slide details
    this.updateAriaNotification();
  }

  /**
   * destroySwiper
   * - Destroy the Swiper instance, if it exists
   */
  destroySwiper() {
    if (this.swiper) {
      this.swiper.destroy(true, true);
      this.swiper = null;
    }
  }

  /**
   * updateAriaNotification
   * - Manually updates the swiper-notification element with slide change details
   */
  updateAriaNotification() {
    if (!this.swiper) return;

    // Find the swiper-notification element specific to this Swiper instance
    const notification = this.sliderTarget.querySelector('.swiper-notification');

    // Get the current slide index and total slides
    const currentSlideIndex = this.swiper?.realIndex + 1 ?? -1;
    const totalSlides = this.sliderTarget.dataset?.numSlides ?? this.swiper.slides?.length;

    // If it exists and the currentSlideIndex is valid (Swiper is initialized properly)
    if (notification && currentSlideIndex > -1) {
      // Update the innerText of the notification element
      notification.innerText = `Slide ${currentSlideIndex} of ${totalSlides} is active.`;
    }
  }

  /**
   * applyFilter
   * - Apply the filter to the slides based on the filterValue
   * - The bulk of the filtering must be done here in the `experiences_module_controller`, since the items are present
   *  on the experiences_module component and not the experiences_filter partial.  I'm not super happy with this, but
   *  it is the most hands-off way to implement the filtering that I could think of at this time.
   * @param event
   */
  applyFilter(event) {
    // Get the filterValue from the event detail
    const filterValue = event?.detail?.filterValue || '';

    // If Swiper is initialized
    if (this.swiper) {
      // Remove all slides, so we can add only the ones that match the filter to ensure that Swiper functions correctly
      this.swiper.removeAllSlides();
    }

    // Filter to only slides that match the filter value
    let filteredItems = [];
    if (filterValue === 'everyone' || !filterValue) {
      // We want to show all slides if the filter is set to everyone or there is no value
      filteredItems = this.allItems;
    } else {
      // Otherwise, filter the slides based on the filter value
      filteredItems = this.allItems?.filter((slide) => {
        const slideFilters = slide.dataset.filter ? slide.dataset.filter.split(' ') : [];
        return slideFilters.includes(filterValue);
      });
    }

    // If we are using the slider layout
    if (this.usesSlider) {
      // Add filtered slides back to Swiper
      this.swiper.appendSlide(filteredItems);

      // Update Swiper
      this.swiper.update();

      // If the screen size is larger than 700px
      if (window.innerWidth > 700) {
        // Reset Swiper to the center slide
        const centerSlideIndex = Math.floor(filteredItems.length / 2);
        this.swiper.slideTo(centerSlideIndex);
      } else {
        // Reset Swiper to the first slide
        this.swiper.slideTo(0);
      }

      // Apply the `.swiper-button-disabled` class to the navigation buttons,
      // if there are the same number or fewer slides than the slidesPerView
      if (filteredItems.length <= this.swiper.params.slidesPerView) {
        // Disable the navigation buttons
        this.nextTarget.classList.add('swiper-button-disabled');
        this.prevTarget.classList.add('swiper-button-disabled');
      }

      // Update the aria-notification element with the initial slide details
      this.updateAriaNotification();
    } else {
      // For grid layout, show/hide items based on the filter
      this.allItems.forEach((item) => {
        // If the item is in the filteredItems array, show it
        if (filteredItems.includes(item)) {
          item.classList.remove('-hide-item');
          item.setAttribute('aria-hidden', 'false');
        } else {
          // Otherwise, hide it
          item.classList.add('-hide-item');
          item.setAttribute('aria-hidden', 'true');
        }
      });
    }
  }

}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['select'];

  connect() {
    this.initDropkick();
    window.addEventListener('resize', this.windowResized.bind(this));
  }

  disconnect() {
    if (this.dkSelect) {
      this.dkSelect.dispose();
      this.dkSelect = null;
    }
    window.removeEventListener('resize', this.windowResized.bind(this));
  }

  /**
   * initDropkick
   * - Initialize the Dropkick instance on the select element
   */
  initDropkick() {
    // Short-circuit if the select element is not present
    if (!this.selectTarget) return;

    // FIXME : Dropkick is included in the project as a part of the `/vendor/assets/` directory,
    //  and imported in the `application.js` file used primarily with the CoffeeScript files.
    //  If we ever remove the CoffeeScript files, we should consider installing and importing Dropkick in package.json,
    //  or including it in the project via Webpack.  I left this as-is for now to avoid adding duplicate dependencies.
    const self = this;
    this.dkSelect = new Dropkick(this.selectTarget, {
      mobile: true,
      initialize: function () {
        // Set the initial filter value from the URL
        const filterby = self.getUrlParameter('filterby');
        // If the filterby parameter is present in the URL, select the corresponding option
        if (filterby) {

          // Check if the filterby value exists in the select options,
          //  by creating an array of the select options to check filterby against each option's value
          const optionExists = Array.from(self.selectTarget.options).some(
            (option) => option.value === filterby
          );

          // Short-circuit if the option does not exist
          if (!optionExists) return;

          // Otherwise, select the option and apply the filter
          this.select(filterby);
          self.applyFilter(filterby);
        }
      },
      change: function () {
        // On change, apply the selected filter
        const filterValue = this.value;
        self.applyFilter(filterValue);
      },
    });
  }

  /**
   * getUrlParameter
   * - Get the value of a URL parameter by name
   * @param name
   * @returns {string}
   */
  getUrlParameter(name) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  }

  /**
   * applyFilter
   * - Apply the filter to the slides based on the filterValue
   * @param filterValue
   */
  applyFilter(filterValue) {
    // Create a custom event with the filterValue, which we'll dispatch to the parent element using event bubbling
    const event = new CustomEvent('filterChange', {
      detail: { filterValue },
      bubbles: true,
    });
    // Dispatch the event
    this.element.dispatchEvent(event);
  }

  /**
   * windowResized
   * - Handle the window resize event
   *  - Re-apply the filter to the slides since the layout may have changed and Swiper may have recalculated the slides
   */
  windowResized() {
    this.applyFilter(this.dkSelect.value);
  }
}
